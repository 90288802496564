import {
  createMemoryHistory,
  createRouter,
  RouteRecordRaw,
} from 'vue-router';
import {
  EAppIdMap,
} from '@/types/app-type';
import {
  ERouteName,
} from './route-name';

const AlarmStatisticsWrapper = () => import('@/views/AlarmStatisticsWrapper.vue');

export const ANONYMOUS_ROUTES: ERouteName[] = [
  ERouteName.PLAYGROUND,
  ERouteName.PRIVACY_POLICY,
];

export const AUTH_ROUTES: ERouteName[] = [
  ERouteName.LOGIN,
];

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ERouteName.HOME,
    component: () => import('@/page/home/home.vue'),
    meta: {
      titleI18nKey: 'page.home.title',
      permissionList: [],
    },
  },
  {
    path: '/login',
    name: ERouteName.LOGIN,
    component: () => import('@/views/Login.vue'),
    meta: {
      titleI18nKey: 'page.login.title',
      permissionList: [],
    },
  },
  {
    name: ERouteName.PRIVACY_POLICY,
    path: '/privacy-policy',
    component: () => import('@/page/privacy-policy/privacy-policy.vue'),
    meta: {
      titleI18nKey: 'page.privacyPolicy.title',
      permissionList: [],
    },
  },
  {
    path: '/app-overview',
    name: ERouteName.APP_OVERVIEW,
    component: () => import('@/page/app-overview/app-overview.vue'),
    meta: {
      titleI18nKey: 'page.appOverview.title',
      permissionList: [],
    },
  },
  {
    path: '/release-notes',
    name: ERouteName.RELEASE_NOTES,
    component: () => import('@/views/ReleaseNotes.vue'),
    meta: {
      titleI18nKey: 'page.releaseNotes.title',
      permissionList: [],
    },
  },
  {
    path: '/certification',
    name: ERouteName.CERTIFICATION,
    component: () => import('@/page/certification/certification.vue'),
    redirect: () => ({
      name: ERouteName.CERTIFICATION_AUDIT,
    }),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.CERTIFICATION}`,
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: 'audit',
        name: ERouteName.CERTIFICATION_AUDIT,
        component: () => import('@/page/certification/audit/audit.vue'),
        meta: {
          titleI18nKey: 'page.certification.audit.title',
          permissionList: [
            {
              appId: EAppIdMap.CERTIFICATION,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'audit-configuration',
        name: ERouteName.CERTIFICATION_AUDIT_CONFIGURATION,
        component: () => import('@/page/certification/audit-configuration/audit-configuration.vue'),
        meta: {
          titleI18nKey: 'page.certification.auditConfiguration.title',
          permissionList: [
            {
              appId: EAppIdMap.CERTIFICATION,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'quality',
        name: ERouteName.CERTIFICATION_QUALITY,
        component: () => import('@/page/certification/quality/quality.vue'),
        meta: {
          titleI18nKey: 'page.certification.quality.title',
          permissionList: [
            {
              appId: EAppIdMap.CERTIFICATION,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'quality-configuration',
        name: ERouteName.CERTIFICATION_QUALITY_CONFIGURATION,
        component: () => import('@/page/certification/quality-configuration/quality-configuration.vue'),
        meta: {
          titleI18nKey: 'page.certification.qualityConfiguration.title',
          permissionList: [
            {
              appId: EAppIdMap.CERTIFICATION,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/grafana',
    name: ERouteName.GRAFANA,
    component: () => import('@/page/grafana/grafana.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.GRAFANA}`,
      permissionList: [
        {
          appId: EAppIdMap.GRAFANA,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/ml-prototyping',
    name: ERouteName.ML_PROTOTYPING,
    component: () => import('@/page/ml-prototyping/ml-prototyping.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.ML_PROTOTYPING}`,
      permissionList: [
        {
          appId: EAppIdMap.ML_PROTOTYPING,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/audit/result/:auditId',
    name: ERouteName.CERTIFICATION_AUDIT_RESULT,
    component: () => import('@/page/audit-result/audit-result.vue'),
    meta: {
      titleI18nKey: 'page.auditResult.title',
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/quality/result/:reportId',
    name: ERouteName.CERTIFICATION_QUALITY_RESULT,
    component: () => import('@/page/quality/result.vue'),
    meta: {
      titleI18nKey: 'page.certification.title',
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/audit/draft',
    component: () => import('@/page/audit/draft.vue'),
    name: ERouteName.CERTIFICATION_AUDIT_DRAFT,
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.CERTIFICATION}`,
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/quality/draft',
    name: ERouteName.CERTIFICATION_QUALITY_DRAFT,
    component: () => import('@/page/quality/draft.vue'),
    meta: {
      titleI18nKey: 'page.quality.title',
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/quality/settings',
    name: ERouteName.CERTIFICATION_QUALITY_SETTINGS,
    component: () => import('@/page/quality/settings.vue'),
    meta: {
      titleI18nKey: 'page.quality.title',
      permissionList: [
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.CERTIFICATION,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/live-app',
    name: ERouteName.LIVE_APP,
    component: () => import('@/page/live-app/live-app.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.LIVE}`,
      permissionList: [
        {
          appId: EAppIdMap.LIVE,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: 'location/:locationId',
        name: ERouteName.LIVE_APP_LOCATION,
        component: () => import('@/page/live-app/location.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.LIVE}`,
          permissionList: [
            {
              appId: EAppIdMap.LIVE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'equipment/:equipmentId',
        name: ERouteName.LIVE_APP_EQUIPMENT,
        component: () => import('@/page/live-app/equipment.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.LIVE}`,
          permissionList: [
            {
              appId: EAppIdMap.LIVE,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/recipe-builder',
    name: ERouteName.RECIPE_BUILDER,
    component: () => import('@/page/recipe-builder/recipe-builder.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
      permissionList: [
        {
          appId: EAppIdMap.RECIPE,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: 'npm/:customerCode/:locationName/:systemId/:assetDisplayName',
        name: ERouteName.RECIPE_BUILDER_NPM_DETAIL,
        component: () => import('@/views/RecipeBuilder/AllRecipesBuilderView.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'npm/:customerCode/:locationName/:systemId/:assetDisplayName/edit',
        name: ERouteName.RECIPE_BUILDER_VIEW,
        component: () => import('@/views/RecipeBuilder/RecipeBuilderView.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          requiresTile: 'RECIPE_BUILDER',
          additionalAppBarType: 'EQUIPMENT',
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'pt9800/:ptDbId/:workplaceId/:assetId/:recipePrefix',
        name: ERouteName.RECIPE_BUILDER_PT9800_DETAILS,
        component: () => import('@/page/pt9800-recipe/details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'pt9800/:ptDbId/:workplaceId/:assetId/:recipePrefix/recipe/:recipeName',
        name: ERouteName.RECIPE_BUILDER_PT9800_RECIPE_DETAILS,
        component: () => import('@/page/pt9800-recipe/recipe-details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'pt9800/:ptDbId/:workplaceId/:assetId/:recipePrefix/recipe/:recipeName/edit',
        name: ERouteName.RECIPE_BUILDER_PT9800_RECIPE_EDIT,
        component: () => import('@/page/pt9800-recipe/recipe-edit.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Write',
            },
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'pt9800/:ptDbId/:workplaceId/:assetId/:recipePrefix/recipe/create',
        name: ERouteName.RECIPE_BUILDER_PT9800_RECIPE_CREATE,
        component: () => import('@/page/pt9800-recipe/recipe-create.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.RECIPE}`,
          permissionList: [
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Write',
            },
            {
              appId: EAppIdMap.RECIPE,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/upc-pro',
    name: ERouteName.UPC_PRO,
    redirect: '/upc-pro/carbon-oxygen',
    component: () => import('@/views/upcPro/index.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
      requiresTile: 'UPC_PRO',
      additionalAppBarType: 'UPC_PRO',
      permissionList: [
        {
          appId: EAppIdMap.UPC_PRO,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: 'carbon-oxygen',
        name: ERouteName.UPC_PRO_CARBON_OXYGEN,
        component: () => import('@/views/upcPro/CarbonOxygen.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'nitrogen-methanol',
        name: 'nitrogen-methanol',
        component: () => import('@/views/upcPro/NitrogenMethanol.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'endo-generator',
        name: 'endo-generator',
        component: () => import('@/views/upcPro/EndoGenerator.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'tc-conversion',
        name: 'tc-conversion',
        component: () => import('@/views/upcPro/TCConversion.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'shim',
        name: 'shim',
        component: () => import('@/views/upcPro/Shim.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'nitriding',
        name: 'nitriding',
        component: () => import('@/views/upcPro/Nitriding.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'rtds',
        name: 'rtds',
        component: () => import('@/views/upcPro/RTDs.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'converters',
        name: 'converters',
        component: () => import('@/views/upcPro/Converters.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'alloy-factor',
        name: 'alloy-factor',
        component: () => import('@/views/upcPro/AlloyFactor.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'flo-meter',
        name: 'flo-meter',
        component: () => import('@/views/upcPro/FloMeter.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.UPC_PRO}`,
          permissionList: [
            {
              appId: EAppIdMap.UPC_PRO,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/asset-management',
    name: ERouteName.ASSET_MANAGEMENT_OVERVIEW,
    component: () => import('@/page/asset-management/overview.vue'),
    meta: {
      titleI18nKey: 'page.assetManagement.overview.title',
      permissionList: [
        {
          appId: EAppIdMap.ASSET_MANAGEMENT,
          permission: 'Read',
        },
      ],
    },
  },
  // ERouteName.ASSET_MANAGEMENT_OVERVIEW should go before to override "/asset-management" path
  {
    path: '/asset-management',
    component: () => import('@/page/asset-management/sidebar-layout.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
      permissionList: [
        {
          appId: EAppIdMap.ASSET_MANAGEMENT,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: '/asset-management/:assetHierarchyLevel/:assetId/main',
        name: ERouteName.ASSET_MANAGEMENT_MAIN,
        component: () => import('@/page/asset-management/asset-main.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
          permissionList: [
            {
              appId: EAppIdMap.ASSET_MANAGEMENT,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: '/asset-management/:assetHierarchyLevel/:assetId/documents',
        name: ERouteName.ASSET_MANAGEMENT_DOCUMENT,
        component: () => import('@/page/asset-management/document-list.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
          permissionList: [
            {
              appId: EAppIdMap.ASSET_MANAGEMENT,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: '/asset-management/:assetHierarchyLevel/:assetId/sensor-settings',
        name: ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS,
        component: () => import('@/page/asset-management/sensor-settings.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
          permissionList: [
            {
              appId: EAppIdMap.ASSET_MANAGEMENT,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: '/asset-management/:assetHierarchyLevel/:assetId/sensor-mapping',
        name: ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING,
        component: () => import('@/page/asset-management/sensor-mapping.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
          permissionList: [
            {
              appId: EAppIdMap.ASSET_MANAGEMENT,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: '/asset-management/:assetHierarchyLevel/:assetId/data-sources',
        name: ERouteName.ASSET_MANAGEMENT_DATA_SOURCES,
        component: () => import('@/page/asset-management/data-sources.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
          permissionList: [
            {
              appId: EAppIdMap.ASSET_MANAGEMENT,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/operation-calendar',
    name: ERouteName.OPERATION_CALENDAR,
    component: () => import('@/page/operation-calendar/operation-calendar.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.OPERATION_CALENDAR}`,
      permissionList: [
        {
          appId: EAppIdMap.OPERATION_CALENDAR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/maintenance-calendar',
    name: ERouteName.MAINTENANCE_CALENDAR,
    component: () => import('@/page/maintenance-calendar/maintenance-calendar.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MAINTENANCE_CALENDAR}`,
      permissionList: [
        {
          appId: EAppIdMap.MAINTENANCE_CALENDAR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/maintenance-calendar/reports',
    name: ERouteName.MAINTENANCE_CALENDAR_REPORT_LIST,
    component: () => import('@/page/maintenance-calendar/maintenance-calendar-report-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MAINTENANCE_CALENDAR}`,
      permissionList: [
        {
          appId: EAppIdMap.MAINTENANCE_CALENDAR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/maintenance-calendar/jobs',
    name: ERouteName.MAINTENANCE_CALENDAR_JOB_LIST,
    component: () => import('@/page/maintenance-calendar/maintenance-calendar-job-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MAINTENANCE_CALENDAR}`,
      permissionList: [
        {
          appId: EAppIdMap.MAINTENANCE_CALENDAR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/maintenance-calendar/tasks',
    name: ERouteName.MAINTENANCE_CALENDAR_TASK_LIST,
    component: () => import('@/page/maintenance-calendar/maintenance-calendar-task-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MAINTENANCE_CALENDAR}`,
      permissionList: [
        {
          appId: EAppIdMap.MAINTENANCE_CALENDAR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/production-logs',
    name: ERouteName.PRODUCTION_LOG_LIST,
    component: () => import('@/page/production-logs/production-log-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_LOGS}`,
      permissionList: [
        {
          appId: EAppIdMap.PRODUCTION_LOGS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/production-logs/comparison',
    name: ERouteName.JOB_COMPARISON,
    component: () => import('@/page/job-comparison/job-comparison.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_LOGS}`,
      permissionList: [
        {
          appId: EAppIdMap.PRODUCTION_LOGS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/production-logs/:jobId',
    name: ERouteName.JOB_CHART,
    component: () => import('@/page/job-chart/job-chart.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_LOGS}`,
      permissionList: [
        {
          appId: EAppIdMap.PRODUCTION_LOGS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/production-statistics',
    name: ERouteName.PRODUCTION_STATISTICS,
    component: () => import('@/page/production-statistics/production-statistics.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_STATISTICS}`,
      permissionList: [
        {
          appId: EAppIdMap.PRODUCTION_STATISTICS,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: ':statisticsLevel/:itemId',
        name: ERouteName.PRODUCTION_STATISTICS_DETAILS,
        component: () => import('@/page/production-statistics/details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_STATISTICS}`,
          permissionList: [
            {
              appId: EAppIdMap.PRODUCTION_STATISTICS,
              permission: 'Read',
            },
          ],
        },
      },
      {
        // only for jobs and recipes
        path: ':statisticsLevel/:parentId/:itemId',
        name: ERouteName.PRODUCTION_STATISTICS_DETAILS_JOB_RECIPE,
        component: () => import('@/page/production-statistics/details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_STATISTICS}`,
          permissionList: [
            {
              appId: EAppIdMap.PRODUCTION_STATISTICS,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/production-statistics/comparison',
    name: 'production-statistics-compare',
    component: () => import('@/views/ProductionStatisticsComparison.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'PRODUCTION_STATISTICS',
      titleI18nKey: `api.AppId.${EAppIdMap.PRODUCTION_STATISTICS}`,
      permissionList: [
        {
          appId: EAppIdMap.PRODUCTION_STATISTICS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/recipe',
    name: ERouteName.RECIPE_ON_DEMAND,
    component: () => import('@/views/recipeOnDemand/Recipes.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'RECIPE_ON_DEMAND',
      titleI18nKey: `api.AppId.${EAppIdMap.RECIPE_ON_DEMAND}`,
      permissionList: [
        {
          appId: EAppIdMap.RECIPE_ON_DEMAND,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/recipe/:recipeId',
    name: 'recipe-details',
    component: () => import('@/views/recipeOnDemand/RecipesDetails.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'RECIPE_ON_DEMAND',
      titleI18nKey: `api.AppId.${EAppIdMap.RECIPE_ON_DEMAND}`,
      permissionList: [
        {
          appId: EAppIdMap.RECIPE_ON_DEMAND,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/asset-management/equipments/:id/instructions/:instructionId',
    name: 'instructions',
    component: () => import('@/views/InstructionDetails.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'ASSET_MANAGEMENT',
      titleI18nKey: `api.AppId.${EAppIdMap.ASSET_MANAGEMENT}`,
      permissionList: [
        {
          appId: EAppIdMap.ASSET_MANAGEMENT,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/performance-dashboard',
    name: ERouteName.PERFORMANCE_DASHBOARD,
    component: () => import('@/page/performance-dashboard/performance-dashboard.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PERFORMANCE_DASHBOARD}`,
      permissionList: [
        {
          appId: EAppIdMap.PERFORMANCE_DASHBOARD,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: 'location/:assetId',
        name: ERouteName.PERFORMANCE_DASHBOARD_LOCATION,
        component: () => import('@/page/performance-dashboard/performance-dashboard-details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.PERFORMANCE_DASHBOARD}`,
          permissionList: [
            {
              appId: EAppIdMap.PERFORMANCE_DASHBOARD,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: 'equipment/:assetId',
        name: ERouteName.PERFORMANCE_DASHBOARD_EQUIPMENT,
        component: () => import('@/page/performance-dashboard/performance-dashboard-details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.PERFORMANCE_DASHBOARD}`,
          permissionList: [
            {
              appId: EAppIdMap.PERFORMANCE_DASHBOARD,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/performance-dashboard/:id',
    name: 'performance-dashboard-furnace',
    component: () => import('@/views/PerformanceDashboardFurnace.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'PERFORMANCE_DASHBOARD',
      titleI18nKey: `api.AppId.${EAppIdMap.PERFORMANCE_DASHBOARD}`,
      additionalAppBarType: 'EQUIPMENT',
      equipmentMainRoute: 'performance-dashboard',
      permissionList: [
        {
          appId: EAppIdMap.PERFORMANCE_DASHBOARD,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/user-management',
    name: ERouteName.USER_MANAGEMENT,
    component: () => import('@/page/user-management/user-management.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'USER_MANAGEMENT',
      titleI18nKey: `api.AppId.${EAppIdMap.USER_MANAGEMENT}`,
      permissionList: [
        {
          appId: EAppIdMap.USER_MANAGEMENT,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/role-management',
    name: ERouteName.ROLE_MANAGEMENT,
    component: () => import('@/page/user-management/role-management.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.USER_MANAGEMENT}`,
      permissionList: [
        {
          appId: EAppIdMap.USER_MANAGEMENT,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.USER_MANAGEMENT,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/remote-control',
    name: ERouteName.REMOTE_CONTROL,
    component: () => import('@/page/remote-control/remote-control.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.REMOTE_CONTROL}`,
      permissionList: [
        {
          appId: EAppIdMap.REMOTE_CONTROL,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: ':assetId',
        name: ERouteName.REMOTE_CONTROL_DETAILS,
        component: () => import('@/page/remote-control/details.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.REMOTE_CONTROL}`,
          permissionList: [
            {
              appId: EAppIdMap.REMOTE_CONTROL,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/history-and-trends/:equipmentId?',
    name: ERouteName.HISTORY_AND_TRENDS,
    component: () => import('@/page/history-and-trends/history-and-trends.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.HISTORY_AND_TRENDS}`,
      permissionList: [
        {
          appId: EAppIdMap.HISTORY_AND_TRENDS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/shop-layout',
    name: ERouteName.SHOP_LAYOUT,
    component: () => import('@/views/ShopLayout.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'SHOP_LAYOUT',
      titleI18nKey: `api.AppId.${EAppIdMap.SHOP_LAYOUT}`,
      permissionList: [
        {
          appId: EAppIdMap.SHOP_LAYOUT,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/pt9800/:view?',
    name: ERouteName.PT9800_CONFIGURATION,
    component: () => import('@/page/pt9800-configuration/pt9800-configuration.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PT9800_CONFIGURATION}`,
      permissionList: [
        {
          appId: EAppIdMap.PT9800_CONFIGURATION,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/alarmStatistics',
    name: ERouteName.ALARM_STATISTICS,
    component: AlarmStatisticsWrapper,
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'ALARM_STATISTICS',
      titleI18nKey: `api.AppId.${EAppIdMap.ALARM_STATISTICS}`,
      permissionList: [
        {
          appId: EAppIdMap.ALARM_STATISTICS,
          permission: 'Read',
        },
      ],
      additionalAppBarType: 'EQUIPMENT',
      equipmentDetailsRoute: 'alarmStatisticsDetails',
      useApiV3: true,
    },
  },
  {
    path: '/alarmStatistics/:equipment_id',
    name: 'alarmStatisticsDetails',
    component: AlarmStatisticsWrapper,
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'ALARM_STATISTICS',
      titleI18nKey: `api.AppId.${EAppIdMap.ALARM_STATISTICS}`,
      permissionList: [
        {
          appId: EAppIdMap.ALARM_STATISTICS,
          permission: 'Read',
        },
      ],
      additionalAppBarType: 'EQUIPMENT',
      useApiV3: true,
    },
  },
  {
    path: '/furnace-view/:id/:location',
    name: 'furnace-view',
    component: () => import('@/pt9800/views/furnaceView.vue'),
    meta: {
      // Requaerd for EquipmentTreeview.vue
      requiresTile: 'SHOP_LAYOUT',
      titleI18nKey: `api.AppId.${EAppIdMap.SHOP_LAYOUT}`,
      permissionList: [
        {
          appId: EAppIdMap.SHOP_LAYOUT,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/customer',
    name: ERouteName.CUSTOMER,
    component: () => import('@/page/customer/customer-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.CUSTOMER}`,
      permissionList: [
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/customer/:customerId',
    name: ERouteName.CUSTOMER_DETAILS,
    component: () => import('@/page/customer/details.vue'),
    meta: {
      titleI18nKey: 'page.customerDetails.title',
      permissionList: [
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/customer/:customerId/edit',
    name: ERouteName.CUSTOMER_EDIT,
    component: () => import('@/page/customer/edit.vue'),
    meta: {
      titleI18nKey: 'page.customerEdit.title',
      permissionList: [
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/customer/create',
    name: ERouteName.CUSTOMER_CREATE,
    component: () => import('@/page/customer/create.vue'),
    meta: {
      titleI18nKey: 'page.customerCreate.title',
      permissionList: [
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.CUSTOMER,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/parts',
    name: ERouteName.PART,
    component: () => import('@/page/part/part.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PARTS}`,
      permissionList: [
        {
          appId: EAppIdMap.PARTS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/parts/:partId',
    name: ERouteName.PART_DETAILS,
    component: () => import('@/page/part/details.vue'),
    meta: {
      titleI18nKey: 'page.partDetails.title',
      permissionList: [
        {
          appId: EAppIdMap.PARTS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/parts/:partId/edit',
    name: ERouteName.PART_EDIT,
    component: () => import('@/page/part/edit.vue'),
    meta: {
      titleI18nKey: 'page.partEdit.title',
      permissionList: [
        {
          appId: EAppIdMap.PARTS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/parts/create',
    name: ERouteName.PART_CREATE,
    component: () => import('@/page/part/create.vue'),
    meta: {
      titleI18nKey: 'page.partCreate.title',
      permissionList: [
        {
          appId: EAppIdMap.PARTS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/parts/settings',
    name: ERouteName.PART_SETTINGS,
    component: () => import('@/page/part/settings.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.PARTS}`,
      permissionList: [
        {
          appId: EAppIdMap.PARTS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/anomaly-detection',
    name: ERouteName.ANOMALY_DETECTION,
    component: () => import('@/page/anomaly-detection/anomaly-detection.vue'),
    meta: {
      titleI18nKey: 'page.anomalyDetection.title',
      permissionList: [
        {
          appId: EAppIdMap.ANOMALY_DETECTION,
          permission: 'Read',
        },
      ],
    },
    children: [
      {
        path: ':equipmentId',
        name: ERouteName.ANOMALY_DETECTION_EQUIPMENT,
        component: () => import('@/page/anomaly-detection/equipment.vue'),
        meta: {
          titleI18nKey: 'page.anomalyDetectionEquipment.title',
          permissionList: [
            {
              appId: EAppIdMap.ANOMALY_DETECTION,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: ':equipmentId/settings',
        name: ERouteName.ANOMALY_DETECTION_EQUIPMENT_SETTINGS,
        component: () => import('@/page/anomaly-detection/equipment-settings.vue'),
        meta: {
          titleI18nKey: 'page.anomalyDetectionEquipmentSettings.title',
          permissionList: [
            {
              appId: EAppIdMap.ANOMALY_DETECTION,
              permission: 'Read',
            },
          ],
        },
      },
      {
        path: ':equipmentId/job-feature/:jobFeatureId',
        name: ERouteName.ANOMALY_DETECTION_JOB_FEATURE_DETAILS,
        component: () => import('@/page/anomaly-detection/job-feature-details.vue'),
        meta: {
          titleI18nKey: 'page.anomalyDetection.jobFeatures.title',
          permissionList: [
            {
              appId: EAppIdMap.ANOMALY_DETECTION,
              permission: 'Read',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/simulator',
    name: ERouteName.SIMULATOR,
    component: () => import('@/page/simulator/create.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/simulator/settings',
    name: ERouteName.SIMULATOR_SETTINGS,
    component: () => import('@/page/simulator/simulator-settings.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/quenching-media/:quenchingMediaId',
    name: ERouteName.SIMULATOR_SETTINGS_QUENCHING_MEDIA_DETAILS,
    component: () => import('@/page/simulator/quenching-media/details.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/quenching-media/:quenchingMediaId/edit',
    name: ERouteName.SIMULATOR_SETTINGS_QUENCHING_MEDIA_EDIT,
    component: () => import('@/page/simulator/quenching-media/edit.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/quenching-media/create',
    name: ERouteName.SIMULATOR_SETTINGS_QUENCHING_MEDIA_CREATE,
    component: () => import('@/page/simulator/quenching-media/create.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/material-calculation-model/:materialCalculationModelId',
    name: ERouteName.SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_DETAILS,
    component: () => import('@/page/simulator/material-calculation-model/details.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/material-calculation-model/:materialCalculationModelId/edit',
    name: ERouteName.SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_EDIT,
    component: () => import('@/page/simulator/material-calculation-model/edit.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/simulator/settings/material-calculation-model/create',
    name: ERouteName.SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_CREATE,
    component: () => import('@/page/simulator/material-calculation-model/create.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.SIMULATOR}`,
      permissionList: [
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.SIMULATOR,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/materials',
    name: ERouteName.MATERIAL_LIST,
    component: () => import('@/page/material/material-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/materials/settings',
    name: ERouteName.MATERIAL_SETTINGS,
    component: () => import('@/page/material/material-settings.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/materials/groups',
    name: ERouteName.MATERIAL_GROUPS,
    component: () => import('@/page/material/material-group-list.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/materials/:materialId',
    name: ERouteName.MATERIAL_DETAILS,
    component: () => import('@/page/material/material-details.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
      ],
    },
  },
  {
    path: '/materials/:materialId/edit',
    name: ERouteName.MATERIAL_EDIT,
    component: () => import('@/page/material/material-edit.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/materials/create',
    name: ERouteName.MATERIAL_CREATE,
    component: () => import('@/page/material/material-create.vue'),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.MATERIALS}`,
      permissionList: [
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Read',
        },
        {
          appId: EAppIdMap.MATERIALS,
          permission: 'Write',
        },
      ],
    },
  },
  {
    path: '/settings',
    name: ERouteName.SETTINGS,
    component: () => import('@/page/general-settings/general-settings.vue'),
    redirect: () => ({
      name: ERouteName.SETTINGS_APPEARANCE,
    }),
    meta: {
      titleI18nKey: `api.AppId.${EAppIdMap.TENANT_SETTINGS}`,
      // No permissions required. User always has access to it's own user
      // settings and check for tenant settings is done within the page
      permissionList: [],
    },
    children: [
      {
        path: 'appearance',
        name: ERouteName.SETTINGS_APPEARANCE,
        component: () => import('@/page/general-settings/appearance.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.TENANT_SETTINGS}`,
          permissionList: [],
        },
      },
      {
        path: 'tenant-settings/:appId',
        name: ERouteName.SETTINGS_TENANT,
        component: () => import('@/page/general-settings/app-tenant-settings.vue'),
        meta: {
          titleI18nKey: `api.AppId.${EAppIdMap.TENANT_SETTINGS}`,
          permissionList: [
            {
              appId: EAppIdMap.TENANT_SETTINGS,
              permission: 'Read',
            },
            {
              appId: EAppIdMap.TENANT_SETTINGS,
              permission: 'Write',
            },
          ],
        },
      },
    ],
  },
];

// used in e2e tests
export const memoryRouter = createRouter({
  routes,
  history: createMemoryHistory(),
});
