import {
  TTranslationAppearanceFormTranslations,
} from './appearance.ts';

const appearanceFormTranslations: TTranslationAppearanceFormTranslations = {
  language: {
    label: 'Sprache',
    tooltip: 'Ermöglicht es, die gewünschte Sprache für die Benutzeroberfläche auszuwählen.',
  },
  timeFormat: {
    label: 'Zeitformat',
    tooltip: 'Definiert wie Daten und Zeitstempel angezeigt werden.',
  },
  fontSize: {
    label: 'Seiteskalierung',
    tooltip: 'Ermöglicht die größe von Schrift und Bedienelement anzupassen.',
  },
};

export default {
  subline: 'Ändere das Erscheinungsbild von Qmulus.',
  appearanceForm: appearanceFormTranslations,
};
