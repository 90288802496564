import {
  acceptHMRUpdate, defineStore,
} from 'pinia';
import {
  AxiosError, AxiosResponse,
} from 'axios';
import {
  i18n,
} from '@i18n';
import {
  z,
} from 'zod';
import vuexStore from '@/store/index.js';
import {
  components,
} from '@/types/openapi.ts';
import {
  ICustomStorageItem,
} from '@/utils/custom-storage/export';
import {
  createOrUpdateCustomStorage, getCustomStorage,
} from '@/api/custom-storage';
import {
  asyncRequestActionList,
} from './util/request';
import {
  ICustomPerformanceDashboardConfig,
  ICustomProductionStatisticsConfig,
} from '@/utils/custom-storage/custom-production-statistics-config';
import {
  IJobListPreset,
} from '@/utils/custom-storage/job-list-filter.ts';
import {
  getConsumptionMeaningKeyList,
} from '@/types/meaning-util.ts';
import {
  EDashboardPanelSize,
} from '@/components/dashboard-panel/dashboard-panel.ts';
import {
  consumptionMeaningMap,
} from '@/types/meaning-type.ts';
import {
  EVisualisation,
} from '@/types/performance-dashboard';
import {
  getDefaultLanguage,
  getDefaultTimeFormat,
} from '@/types/localization-store';
import {
  TImageAssessmentTest,
} from '@/types/quality-report-type';
import zodLocale from '@/constants/zod/export.ts';
import {
  ICustomUserSettings,
} from '@/utils/custom-storage/custom-user-settings';
import {
  DEFAULT_FONT_SIZE,
} from '@/types/font-size';
import {
  CHART_ADDITIONAL_SETTINGS,
  TCustomChartAdditionalSettings,
} from '@/utils/custom-storage/custom-chart-settings';
import {
  changeFontSizeForEntirePage,
} from '@/utils/font-size.ts';

export type TMaterialList = {
  id: number,
  name: string
}

export type ICustomUserConfigId = string;

const DEFAULT_USER_SETTINGS: ICustomStorageItem['customUserSettings'] = {
  language: getDefaultLanguage(),
  timeFormat: getDefaultTimeFormat(),
  fontSize: DEFAULT_FONT_SIZE,
};

export const useCustomUserStorageStore = defineStore('customUserStorage', {
  state: () => ({
    // is prefetched in auth middleware
    customUserSettings: DEFAULT_USER_SETTINGS,
    isCustomerUserSettingsLoaded: false,
    customUserSettingsPromise: undefined as Promise<AxiosResponse<ICustomStorageItem['customUserSettings']>> | undefined,
    customChartAdditionalSettings: undefined as TCustomChartAdditionalSettings | undefined,
    customChartAdditionalSettingsPromise: undefined as Promise<AxiosResponse<TCustomChartAdditionalSettings>> | undefined,
    customJobListPresetList: undefined as IJobListPreset[] | undefined,
    customJobListPresetPromise: undefined as Promise<AxiosResponse<IJobListPreset[]>> | undefined,
    customProductionStatisticsConfigList: undefined as ICustomProductionStatisticsConfig[] | undefined,
    customProductionStatisticsConfigPromise: undefined as Promise<AxiosResponse<ICustomProductionStatisticsConfig[]>> | undefined,
    customPerformanceDashboardConfig: undefined as ICustomStorageItem['customPerformanceDashboardConfig'] | undefined,
    customPerformanceDashboardConfigPromise: undefined as Promise<AxiosResponse<ICustomStorageItem['customPerformanceDashboardConfig']>> | undefined,
    customPinnedAppMap: undefined as ICustomStorageItem['customPinnedAppMap'] | undefined,
    customPinnedAppMapPromise: undefined as Promise<AxiosResponse<ICustomStorageItem['customPinnedAppMap']>> | undefined,
    qualityReportDraft: undefined as ICustomStorageItem['qualityReportDraft'] | undefined,
    qualityReportDraftPromise: undefined as Promise<AxiosResponse<ICustomStorageItem['qualityReportDraft']>> | undefined,
  }),
  getters: {
    isCustomJobListPresetListLoading: (state) => state.customJobListPresetPromise !== undefined,
    customProductionStatisticsConfigIsLoading: (state) => state.customProductionStatisticsConfigPromise !== undefined,
    customPerformanceDashboardConfigIsLoading: (state) => state.customProductionStatisticsConfigPromise !== undefined,
    customPinnedAppMapIsLoading: (state) => state.customPinnedAppMapPromise !== undefined,
    qualityReportDraftIsLoading: (state) => state.qualityReportDraftPromise !== undefined,
  },
  actions: {
    async loadCustomUserSettings(force: boolean): Promise<ICustomUserSettings> {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'customUserSettings',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.customUserSettings = DEFAULT_USER_SETTINGS;

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.customUserSettings;
              return Promise.resolve(error.response!);
            }

            throw error;
          })
          .then((response) => {
            changeFontSizeForEntirePage(response.data.fontSize || DEFAULT_FONT_SIZE);

            // TODO remove eventually
            vuexStore.commit('setLanguage', response.data.language);
            vuexStore.commit('setNumberFormat', response.data.language);
            vuexStore.commit('setDateFormat', response.data.timeFormat);

            return response;
          }),
        list: this.customUserSettings,
        promise: this.customUserSettingsPromise,
        setList: (data) => {
          // in case attributes have been added to the settings later, we dynamically
          // patch the settings for users where new attributes are missing.
          this.customUserSettings = Object.assign(DEFAULT_USER_SETTINGS, data);
          this.isCustomerUserSettingsLoaded = true;
        },
        setPromise: (promise) => {
          this.customUserSettingsPromise = promise;
        },
        force,
      });
    },
    async loadCustomUserSettingsOnce() {
      return this.loadCustomUserSettings(!this.isCustomerUserSettingsLoaded);
    },
    async createOrUpdateCustomUserSettings(config: ICustomStorageItem['customUserSettings']) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'customUserSettings',
          },
          bodyParams: config,
        }).then((response) => {
          i18n.global.locale = response.data.language;
          z.setErrorMap(zodLocale[response.data.language]);
          return response;
        }),
        list: this.customUserSettings,
        promise: this.customUserSettingsPromise,
        setList: (data) => {
          this.customUserSettings = data;
        },
        setPromise: (promise) => {
          this.customUserSettingsPromise = promise;
        },
        force: true,
      });
    },

    async loadChartAdditionalSettings() {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'customChartAdditionalSettings',
          },
        }).catch((error) => {
          if (error instanceof AxiosError
            && error?.response?.status === 404) {
            this.customChartAdditionalSettings = {};

            // eslint-disable-next-line no-param-reassign
            error.response!.status = 200;
            // eslint-disable-next-line no-param-reassign
            error.response!.data = this.customChartAdditionalSettings;
            return Promise.resolve(error.response!);
          }

          throw error;
        }),
        list: this.customChartAdditionalSettings,
        promise: this.customChartAdditionalSettingsPromise,
        setList: (data) => {
          this.customChartAdditionalSettings = data;
        },
        setPromise: (promise) => {
          this.customChartAdditionalSettingsPromise = promise;
        },
      });
    },
    async createOrUpdateChartAdditionalSettings(settingName: typeof CHART_ADDITIONAL_SETTINGS[number], settingValue: boolean) {
      const currentSettings = JSON.parse(JSON.stringify(this.customChartAdditionalSettings));
      currentSettings[settingName] = settingValue;
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'customChartAdditionalSettings',
          },
          bodyParams: currentSettings,
        }),
        list: this.customChartAdditionalSettings,
        promise: this.customChartAdditionalSettingsPromise,
        setList: (data) => {
          this.customChartAdditionalSettings = data;
        },
        setPromise: (promise) => {
          this.customChartAdditionalSettingsPromise = promise;
        },
        force: true,
      });
    },

    async loadCustomPinnedAppMap() {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'customPinnedAppMap',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.customPinnedAppMap = {} as ICustomStorageItem['customPinnedAppMap'];

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.customPinnedAppMap;
              return Promise.resolve(error.response!);
            }

            throw error;
          }),
        list: this.customPinnedAppMap,
        promise: this.customPinnedAppMapPromise,
        setList: (data) => {
          this.customPinnedAppMap = data;
        },
        setPromise: (promise) => {
          this.customPinnedAppMapPromise = promise;
        },
      });
    },
    async createOrUpdateCustomPinnedAppMap(config: ICustomStorageItem['customPinnedAppMap']) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'customPinnedAppMap',
          },
          bodyParams: config,
        }),
        list: this.customPinnedAppMap,
        promise: this.customPinnedAppMapPromise,
        setList: (data) => {
          this.customPinnedAppMap = data;
        },
        setPromise: (promise) => {
          this.customPinnedAppMapPromise = promise;
        },
        force: true,
      });
    },
    async toggleCustomPinnedApp(appId: components['schemas']['AppId']) {
      const customPinnedAppMap = await this.loadCustomPinnedAppMap();
      if (!(customPinnedAppMap[appId])) {
        customPinnedAppMap[appId] = true;
      } else {
        delete customPinnedAppMap[appId];
      }
      await this.createOrUpdateCustomPinnedAppMap(customPinnedAppMap);
    },
    async loadCustomPerformanceDashboardConfig() {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'customPerformanceDashboardConfig',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.customPerformanceDashboardConfig = {
                panelList: Object.values(EVisualisation),
              };

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.customPerformanceDashboardConfig;
              return Promise.resolve(error.response!);
            }

            throw error;
          }),
        list: this.customPerformanceDashboardConfig,
        promise: this.customPerformanceDashboardConfigPromise,
        setList: (data) => {
          this.customPerformanceDashboardConfig = data;
        },
        setPromise: (promise) => {
          this.customPerformanceDashboardConfigPromise = promise;
        },
      });
    },
    async createOrUpdateCustomPerformanceDashboardConfig(config: ICustomPerformanceDashboardConfig) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'customPerformanceDashboardConfig',
          },
          bodyParams: config,
        }),
        list: this.customPerformanceDashboardConfig,
        promise: this.customPerformanceDashboardConfigPromise,
        setList: (data) => {
          this.customPerformanceDashboardConfig = data;
        },
        setPromise: (promise) => {
          this.customPerformanceDashboardConfigPromise = promise;
        },
        force: true,
      });
    },
    async loadCustomProductionStatisticsConfig() {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'customProductionStatisticsConfigList',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.customProductionStatisticsConfigList = [
                {
                  displayName: i18n.global.t('component.presetSelection.modal.defaultTitle'),
                  id: Math.random(),
                  panelList: [
                    {
                      displayName: i18n.global.t('component.dashboardPanel.defaultTitle'),
                      id: Math.random(),
                      size: EDashboardPanelSize.LARGE,
                      kpiList: [
                        ...getConsumptionMeaningKeyList().filter((meaningKey) => !consumptionMeaningMap[meaningKey as (keyof typeof consumptionMeaningMap)].startsWith('DEPRECATED')),
                      ],
                    },
                  ],
                },
              ];

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.customProductionStatisticsConfigList;
              return Promise.resolve(error.response!);
            }

            throw error;
          }),
        list: this.customProductionStatisticsConfigList,
        promise: this.customProductionStatisticsConfigPromise,
        setList: (data) => {
          this.customProductionStatisticsConfigList = data;
        },
        setPromise: (promise) => {
          this.customProductionStatisticsConfigPromise = promise;
        },
      });
    },
    async createOrUpdateCustomProductionStatisticsConfig(config: ICustomProductionStatisticsConfig[]) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'customProductionStatisticsConfigList',
          },
          bodyParams: config,
        }),
        list: this.customProductionStatisticsConfigList,
        promise: this.customProductionStatisticsConfigPromise,
        setList: (data) => {
          this.customProductionStatisticsConfigList = data;
        },
        setPromise: (promise) => {
          this.customProductionStatisticsConfigPromise = promise;
        },
        force: true,
      });
    },
    async loadCustomJobListPresetList() {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'jobListPresetList',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.customJobListPresetList = [];

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.customJobListPresetList;
              return Promise.resolve(error.response!);
            }

            throw error;
          }),
        list: this.customJobListPresetList,
        promise: this.customJobListPresetPromise,
        setList: (data) => {
          this.customJobListPresetList = data;
        },
        setPromise: (promise) => {
          this.customJobListPresetPromise = promise;
        },
      });
    },
    async createOrUpdateJobListPresetList(config: IJobListPreset[]) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'jobListPresetList',
          },
          bodyParams: config,
        }),
        list: this.customJobListPresetList,
        promise: this.customJobListPresetPromise,
        setList: (data) => {
          this.customJobListPresetList = data;
        },
        setPromise: (promise) => {
          this.customJobListPresetPromise = promise;
        },
        force: true,
      });
    },

    async loadQualityReportDraft(): Promise<ICustomStorageItem['qualityReportDraft']> {
      return asyncRequestActionList({
        callback: () => getCustomStorage({
          path: {
            storageKey: 'qualityReportDraft',
          },
        })
          .catch((error) => {
            if (error instanceof AxiosError
              && error?.response?.status === 404) {
              this.qualityReportDraft = {};

              // eslint-disable-next-line no-param-reassign
              error.response!.status = 200;
              // eslint-disable-next-line no-param-reassign
              error.response!.data = this.qualityReportDraft;
              return Promise.resolve(error.response!);
            }

            throw error;
          }),
        list: this.qualityReportDraft,
        promise: this.qualityReportDraftPromise,
        setList: (data) => {
          this.qualityReportDraft = data;
        },
        setPromise: (promise) => {
          this.qualityReportDraftPromise = promise;
        },
      });
    },

    async createOrUpdateQualityReportDraft(
      config: ICustomStorageItem['qualityReportDraft'],
    ) {
      return asyncRequestActionList({
        callback: () => createOrUpdateCustomStorage({
          path: {
            storageKey: 'qualityReportDraft',
          },
          bodyParams: {
            ...config,
            samples: config.samples
              ? [
                ...config.samples.map((_sample) => ({
                  ..._sample,
                  tests: _sample.tests
                    ? _sample.tests.map((_test) => {
                      // there is internal property which is used for validation progress state
                      // this property, if saved on BE, can hard-lock UI from user
                      // eslint-disable-next-line no-param-reassign
                      delete _test.isLoading;
                      if (
                        _test.testType === 'image'
                        || _test.testType === 'file'
                      ) {
                        // we need it in order to add "invalid" fileData property
                        // @ts-ignore
                        return {
                          ..._test,
                          // we delete fileData, because that data will be saved in DB,
                          // and b64 file might be HUGE
                          fileData: undefined,
                        } as TImageAssessmentTest;
                      }
                      return _test;
                    })
                    : [],
                })),
              ]
              : undefined,
          },
        }),
        list: this.qualityReportDraft,
        promise: this.qualityReportDraftPromise,
        setList: (data) => {
          this.qualityReportDraft = data;
        },
        setPromise: (promise) => {
          this.qualityReportDraftPromise = promise;
        },
        force: true,
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomUserStorageStore, import.meta.hot));
}
