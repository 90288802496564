import {
  TFindMetadataResponse,
} from '@/generated/axios/openapi/core.ts';
import {
  components,
} from '@/types/openapi.ts';
import {
  TComponentValue,
} from './openapi-type';

export enum EDataFormat {
  TIME_SERIES = 'time_series',
  JOB_AGGREGATE= 'job_aggregate',
  EVENT = 'event',
  ALARM_EVENT = 'alarm_event',
  STAGE_CHANGE_EVENT = 'stage_change_event',
  BLOB = 'blob',
}

export const DataFormatMap: Record<components['schemas']['DataFormat'], keyof typeof EDataFormat> = {
  time_series: 'TIME_SERIES',
  job_aggregate: 'JOB_AGGREGATE',
  event: 'EVENT',
  alarm_event: 'ALARM_EVENT',
  stage_change_event: 'STAGE_CHANGE_EVENT',
  blob: 'BLOB',
};

export type TMetadataWithChartConfigOverride = TFindMetadataResponse[number] & {
  meaningId: components['schemas']['TimeseriesMeaning'],
  // we have to add this property which is inconsistent with backend, which expects another property on update
  significantDigitsOverwrite?: TComponentValue<'MetadataUpdate'>['significantDigitsOverwrite']
};
